var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        { staticClass: "pl-0 tw-hidden xl:tw-block", attrs: { cols: "12" } },
        [
          _c(
            "h2",
            {
              staticClass: "mt-5 mb-5 bold primary-color",
              staticStyle: { "letter-spacing": "1px" },
            },
            [_vm._v(" Patient Medical Profile ")]
          ),
        ]
      ),
      _c(
        "b-col",
        { staticClass: "tw-mt-0 md:tw-mt-3 xl:tw-mt-0", attrs: { cols: "12" } },
        [
          _c(
            "b-row",
            { staticClass: "content" },
            [
              _c(
                "b-col",
                { attrs: { cols: "12", md: "12", lg: "4", xl: "3" } },
                [
                  _c(
                    "b-card",
                    {
                      staticClass: "patient-info mb-0",
                      staticStyle: { "background-color": "transparent" },
                      scopedSlots: _vm._u([
                        {
                          key: "header",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "tw-flex tw-justify-between tw-items-center",
                                },
                                [
                                  _c("h3", { staticClass: "mb-0 bold" }, [
                                    _vm._v("Patient Information"),
                                  ]),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "footer",
                          fn: function () {
                            return [
                              !_vm.patientInfo.id
                                ? _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "mb-3",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c("b-skeleton", {
                                            attrs: {
                                              animation: "fade",
                                              type: "button",
                                              width: "100%",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("b-skeleton", {
                                            attrs: {
                                              animation: "fade",
                                              type: "button",
                                              width: "100%",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass:
                                            "mb-3 text-center text-uppercase",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "d-block mb-3",
                                              attrs: { href: "#" },
                                              on: {
                                                click: function ($event) {
                                                  _vm.currentView =
                                                    "prescription-history"
                                                },
                                              },
                                            },
                                            [_vm._v(" View Prescription ")]
                                          ),
                                          _c(
                                            "a",
                                            {
                                              staticClass: "d-block mb-3",
                                              attrs: { href: "#" },
                                              on: {
                                                click: function ($event) {
                                                  _vm.currentView = "reminders"
                                                },
                                              },
                                            },
                                            [_vm._v(" View Reminders ")]
                                          ),
                                          _c(
                                            "a",
                                            {
                                              staticClass: "d-block",
                                              attrs: { href: "#" },
                                              on: {
                                                click: function ($event) {
                                                  _vm.currentView = "followup"
                                                },
                                              },
                                            },
                                            [_vm._v(" View Follow-up History ")]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass:
                                            "mb-3 text-center text-uppercase",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              attrs: { href: "#" },
                                              on: {
                                                click: function ($event) {
                                                  $event.preventDefault()
                                                  _vm.showIntakeHistory = true
                                                },
                                              },
                                            },
                                            [_vm._v(" View Intake History ")]
                                          ),
                                        ]
                                      ),
                                      !_vm.currentDoctor.id
                                        ? _c(
                                            "b-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c(
                                                "b-button",
                                                {
                                                  attrs: {
                                                    pill: "",
                                                    block: "",
                                                    variant: "secondary",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.chatPatient(
                                                        _vm.patientInfo.id
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v(" Chat with Patient ")]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      !_vm.patientInfo.id
                        ? _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { staticClass: "mb-5", attrs: { cols: "12" } },
                                [
                                  _c(
                                    "b-row",
                                    { attrs: { "align-v": "center" } },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "auto" } },
                                        [
                                          _c("b-skeleton", {
                                            attrs: {
                                              animation: "fade",
                                              type: "avatar",
                                              size: "85px",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        [
                                          _c("b-skeleton", {
                                            attrs: { animation: "fade" },
                                          }),
                                          _c("b-skeleton", {
                                            attrs: {
                                              animation: "fade",
                                              type: "button",
                                              width: "100%",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { staticClass: "mb-4", attrs: { cols: "12" } },
                                [
                                  _c(
                                    "b-row",
                                    { attrs: { "align-v": "start" } },
                                    _vm._l(2, function (i) {
                                      return _c(
                                        "b-col",
                                        {
                                          key: i,
                                          attrs: { cols: "12", md: "6" },
                                        },
                                        [
                                          _c("b-skeleton", {
                                            attrs: {
                                              animation: "fade",
                                              height: "10px",
                                              width: "50%",
                                            },
                                          }),
                                          _c("b-skeleton", {
                                            attrs: { animation: "fade" },
                                          }),
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._l(3, function (i) {
                                return _c(
                                  "b-col",
                                  {
                                    key: i,
                                    staticClass: "mb-4",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-row",
                                      { attrs: { "align-v": "start" } },
                                      [
                                        _c(
                                          "b-col",
                                          { attrs: { cols: "12" } },
                                          [
                                            _c("b-skeleton", {
                                              attrs: {
                                                animation: "fade",
                                                height: "10px",
                                                width: "50%",
                                              },
                                            }),
                                            _c("b-skeleton", {
                                              attrs: { animation: "fade" },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }),
                            ],
                            2
                          )
                        : _c("patient-information", {
                            key: _vm.patientInformationKey,
                            attrs: {
                              "patient-info": _vm.patientInfo,
                              "show-notes": _vm.showNotesModal,
                              "show-pricing": _vm.showPricingList,
                              "is-editing": _vm.isEditing,
                              values: _vm.editablePatientInformation,
                            },
                            on: {
                              "update:showNotes": function ($event) {
                                _vm.showNotesModal = $event
                              },
                              "update:show-notes": function ($event) {
                                _vm.showNotesModal = $event
                              },
                              "update:showPricing": function ($event) {
                                _vm.showPricingList = $event
                              },
                              "update:show-pricing": function ($event) {
                                _vm.showPricingList = $event
                              },
                              "update:isEditing": function ($event) {
                                _vm.isEditing = $event
                              },
                              "update:is-editing": function ($event) {
                                _vm.isEditing = $event
                              },
                              "update:values": function ($event) {
                                _vm.editablePatientInformation = $event
                              },
                            },
                          }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.currentView === "reminders"
                ? _c(
                    "b-col",
                    { attrs: { cols: "12", md: "12", lg: "8", xl: "9" } },
                    [_c("reminder", { attrs: { page: "patient-profile" } })],
                    1
                  )
                : _vm.currentView === "prescription-history"
                ? _c(
                    "b-col",
                    { attrs: { cols: "12", md: "12", lg: "8", xl: "9" } },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { staticClass: "mb-4", attrs: { cols: "12" } },
                            [
                              _c(
                                "b-row",
                                { attrs: { "align-v": "center" } },
                                [
                                  _c("b-col", [
                                    _c("h3", { staticClass: "mb-0 bold" }, [
                                      _vm._v("Prescription History"),
                                    ]),
                                  ]),
                                  !_vm.currentDoctor.id
                                    ? _c(
                                        "b-col",
                                        { attrs: { cols: "auto" } },
                                        [
                                          _c(
                                            "b-row",
                                            {
                                              staticClass:
                                                "tw-hidden xl:tw-flex",
                                              attrs: { "align-h": "end" },
                                            },
                                            [
                                              _c(
                                                "b-col",
                                                { attrs: { cols: "auto" } },
                                                [
                                                  !_vm.patientInfo.id
                                                    ? _c("b-skeleton", {
                                                        attrs: {
                                                          animation: "fade",
                                                          type: "button",
                                                          width: "150px",
                                                        },
                                                      })
                                                    : _c(
                                                        "b-button",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "b-modal",
                                                              rawName:
                                                                "v-b-modal.cancelPatient",
                                                              modifiers: {
                                                                cancelPatient: true,
                                                              },
                                                            },
                                                          ],
                                                          staticStyle: {
                                                            "min-width":
                                                              "150px",
                                                          },
                                                          attrs: {
                                                            pill: "",
                                                            size: "sm",
                                                            variant: "danger",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Cancel Patient "
                                                          ),
                                                        ]
                                                      ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-col",
                                                { attrs: { cols: "auto" } },
                                                [
                                                  !_vm.patientInfo.id
                                                    ? _c("b-skeleton", {
                                                        attrs: {
                                                          animation: "fade",
                                                          type: "button",
                                                          width: "150px",
                                                        },
                                                      })
                                                    : _c(
                                                        "b-button",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "b-modal",
                                                              rawName:
                                                                "v-b-modal.viewDosespot",
                                                              modifiers: {
                                                                viewDosespot: true,
                                                              },
                                                            },
                                                          ],
                                                          staticStyle: {
                                                            "min-width":
                                                              "150px",
                                                          },
                                                          attrs: {
                                                            pill: "",
                                                            size: "sm",
                                                            variant:
                                                              "outline-primary",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " View Dosespot "
                                                          ),
                                                        ]
                                                      ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-col",
                                                { attrs: { cols: "auto" } },
                                                [
                                                  !_vm.patientInfo.id
                                                    ? _c("b-skeleton", {
                                                        attrs: {
                                                          animation: "fade",
                                                          type: "button",
                                                          width: "150px",
                                                        },
                                                      })
                                                    : _vm.patientInfo &&
                                                      _vm.patientInfo.id &&
                                                      !_vm.patientInfo.isbanned
                                                    ? _c(
                                                        "b-button",
                                                        {
                                                          staticStyle: {
                                                            "min-width":
                                                              "150px",
                                                          },
                                                          attrs: {
                                                            pill: "",
                                                            size: "sm",
                                                            variant:
                                                              "outline-primary",
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.onPrescribe,
                                                          },
                                                        },
                                                        [_vm._v(" Prescribe ")]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-dropdown",
                                            {
                                              staticClass:
                                                "tw-block xl:tw-hidden",
                                              attrs: {
                                                variant: "link",
                                                "toggle-class":
                                                  "text-decoration-none",
                                                "menu-class":
                                                  "custom-dropdown-menu",
                                                "no-caret": "",
                                                right: "",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "button-content",
                                                    fn: function () {
                                                      return [
                                                        _c("b-icon", {
                                                          attrs: {
                                                            icon: "three-dots-vertical",
                                                          },
                                                        }),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                ],
                                                null,
                                                false,
                                                374507881
                                              ),
                                            },
                                            [
                                              _c(
                                                "b-dropdown-item",
                                                {
                                                  on: {
                                                    click: _vm.onPrescribe,
                                                  },
                                                },
                                                [_vm._v(" Prescribe ")]
                                              ),
                                              _c(
                                                "b-dropdown-item",
                                                {
                                                  directives: [
                                                    {
                                                      name: "b-modal",
                                                      rawName:
                                                        "v-b-modal.viewDosespot",
                                                      modifiers: {
                                                        viewDosespot: true,
                                                      },
                                                    },
                                                  ],
                                                },
                                                [_vm._v(" View Dosepot ")]
                                              ),
                                              _c(
                                                "b-dropdown-item",
                                                {
                                                  directives: [
                                                    {
                                                      name: "b-modal",
                                                      rawName:
                                                        "v-b-modal.cancelPatient",
                                                      modifiers: {
                                                        cancelPatient: true,
                                                      },
                                                    },
                                                  ],
                                                  attrs: {
                                                    "link-class":
                                                      "!tw-text-alert",
                                                  },
                                                },
                                                [_vm._v(" Cancel Patient ")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm.patientInfo.isbanned
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "tw-bg-[#EB5757] tw-text-white tw-p-1 tw-my-4 tw-text-center",
                                    },
                                    [
                                      _vm._v(
                                        " Patient Banned - " +
                                          _vm._s(
                                            _vm.patientInfo.banned_reason
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm.patientInfo.cancelledbyusername
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "tw-bg-[#EB5757] tw-text-white tw-p-1 tw-my-4 tw-text-center",
                                    },
                                    [
                                      _vm._v(
                                        " Canceled on " +
                                          _vm._s(
                                            _vm.parseDate(
                                              _vm.patientInfo
                                                .fullycancelleddatetime,
                                              "MM/dd/yyyy"
                                            )
                                          ) +
                                          " by " +
                                          _vm._s(
                                            _vm.patientInfo
                                              .cancelledbyusertype === "DOCTOR"
                                              ? "Dr. "
                                              : ""
                                          ) +
                                          _vm._s(
                                            _vm.patientInfo.cancelledbyusername
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            {
                              class: {
                                "tw-opacity-75":
                                  _vm.patientInfo.cancelledbyusername ||
                                  _vm.patientInfo.isbanned,
                              },
                              attrs: { cols: "12" },
                            },
                            [
                              _c("section-card-prescription", {
                                ref: "sectionCardPrescription",
                                attrs: {
                                  items: _vm.ongoingItems,
                                  "is-add-refill-modal-open":
                                    _vm.isAddRefillModalOpen,
                                  "selected-restart-prescription":
                                    _vm.selectedRestartPrescription,
                                },
                                on: {
                                  "update:isAddRefillModalOpen": function (
                                    $event
                                  ) {
                                    _vm.isAddRefillModalOpen = $event
                                  },
                                  "update:is-add-refill-modal-open": function (
                                    $event
                                  ) {
                                    _vm.isAddRefillModalOpen = $event
                                  },
                                  "update:selectedRestartPrescription":
                                    function ($event) {
                                      _vm.selectedRestartPrescription = $event
                                    },
                                  "update:selected-restart-prescription":
                                    function ($event) {
                                      _vm.selectedRestartPrescription = $event
                                    },
                                  "refetch-prescriptions":
                                    _vm.cardRefetchPrescriptions,
                                  "refetch-single-prescription":
                                    _vm.cardRefetchPrescriptions,
                                },
                              }),
                              _c(
                                "b-col",
                                {
                                  staticClass: "pr-0 pl-0",
                                  attrs: { cols: "12" },
                                },
                                [
                                  !_vm.isBusy
                                    ? _c("dashboard-table", {
                                        staticClass: "patient-profile-table",
                                        attrs: {
                                          "table-header": _vm.fields,
                                          "table-item": _vm.listViewItems,
                                          "scroll-height":
                                            _vm.ongoingItems.length > 0
                                              ? "300px"
                                              : "calc(90vh - 250px)",
                                          "show-all-prescriptions":
                                            _vm.showAllPrescriptions,
                                          scrollable: "",
                                          "has-search": "",
                                        },
                                        on: {
                                          prescribed: _vm.refetchPrescriptions,
                                          searchPrescription:
                                            _vm.searchPrescription,
                                          "poll-prescriptions":
                                            _vm.cardRefetchPrescriptions,
                                          "toggle-show-all": function ($event) {
                                            _vm.showAllPrescriptions =
                                              !_vm.showAllPrescriptions
                                          },
                                          "open-restart-treatment":
                                            _vm.onOpenRestartTreatmentModal,
                                          "open-add-refill":
                                            _vm.onOpenAddRefillModal,
                                        },
                                      })
                                    : _c("b-skeleton-table", {
                                        attrs: {
                                          animation: "fade",
                                          rows: 5,
                                          columns: 5,
                                        },
                                      }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm.currentView === "followup"
                ? _c(
                    "b-col",
                    { attrs: { cols: "12", md: "12", lg: "8", xl: "9" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "tw-flex tw-justify-between tw-items-center mt-5 mb-4",
                        },
                        [
                          _c("div", { staticClass: "text-primary" }, [
                            _c("h2", { staticClass: "mb-0" }, [
                              _vm._v("Follow-up History"),
                            ]),
                          ]),
                          _c(
                            "b-button",
                            {
                              staticClass: "px-3",
                              staticStyle: { "min-width": "150px" },
                              attrs: {
                                pill: "",
                                size: "sm",
                                variant: "outline-primary",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.isScheduleFollowupOpen = true
                                },
                              },
                            },
                            [_vm._v(" Schedule a follow-up ")]
                          ),
                        ],
                        1
                      ),
                      _c("table-followup", {
                        attrs: {
                          "schedule-followup": _vm.isScheduleFollowupOpen,
                          fields: _vm.followupFields,
                          items: _vm.followupItems,
                          "scroll-height": "calc(90vh - 250px)",
                          "no-header": true,
                          scrollable: "",
                          bvToast: this.$bvToast,
                        },
                        on: {
                          "update:scheduleFollowup": function ($event) {
                            _vm.isScheduleFollowupOpen = $event
                          },
                          "update:schedule-followup": function ($event) {
                            _vm.isScheduleFollowupOpen = $event
                          },
                          refetch: _vm.onFollowupRefetch,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("modal-restart-prescription", {
        attrs: { processing: _vm.isProcessing },
        on: { restart: _vm.onRestartTreatment },
      }),
      _c(
        "b-modal",
        {
          attrs: {
            id: "viewDosespot",
            centered: "",
            "hide-footer": "",
            size: "xl",
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function ({ close }) {
                return [
                  _c("b-container", { attrs: { fluid: "" } }),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "link" },
                      on: { click: close },
                    },
                    [
                      _c("b-icon", {
                        staticStyle: { width: "30px", height: "30px" },
                        attrs: { icon: "x-circle-fill", variant: "secondary" },
                      }),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "b-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _vm.isLoading && !this.dosespotURL
                    ? [
                        _c(
                          "b-row",
                          {
                            staticStyle: { height: "350px" },
                            attrs: { "align-v": "center" },
                          },
                          [
                            _c(
                              "b-col",
                              {
                                staticClass: "text-center",
                                attrs: { cols: "12" },
                              },
                              [
                                _c("h1", { staticClass: "text-primary" }, [
                                  _vm._v("Loading Dosespot..."),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    : _c("iframe", {
                        style: { minHeight: _vm.iframeHeight + "px" },
                        attrs: {
                          src: this.dosespotURL,
                          width: "100%",
                          height: "100%",
                          frameborder: "0",
                          scrolling: "yes",
                          allowtransparency: "true",
                        },
                      }),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "cancelPatient",
            "hide-header": "",
            centered: "",
            "hide-footer": "",
            size: "lg",
          },
        },
        [
          _c(
            "b-row",
            { staticStyle: { position: "relative" } },
            [
              _c(
                "b-col",
                {
                  staticStyle: { position: "absolute", top: "0", left: "0" },
                  attrs: { cols: "12", sm: "5" },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "30",
                        height: "27",
                        viewBox: "0 0 30 27",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M26.4395 18.5298L15.6275 6.36712H14.3725L3.5585 18.532L3.5365 0H0V27H1.3615L15.0005 10.8077L28.5975 26.8985L28.683 27H30V0H26.4175L26.4395 18.5298Z",
                          fill: "#1A6A72",
                        },
                      }),
                    ]
                  ),
                ]
              ),
              _c(
                "b-col",
                {
                  staticClass: "py-6",
                  attrs: { cols: "12", sm: "8", "offset-sm": "2" },
                },
                [
                  _c(
                    "h1",
                    {
                      staticClass: "heading mb-4",
                      staticStyle: { "line-height": "30px" },
                    },
                    [_vm._v("Cancel Patient")]
                  ),
                  _c("p", { staticClass: "mb-4" }, [
                    _vm._v(
                      " Are you sure you want to cancel this patient? This will cancel any outstanding refills, removes them from the three month follow up emails, and makes them non-active patients. "
                    ),
                  ]),
                  _c("ValidationObserver", {
                    ref: "notesForm",
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ handleSubmit }) {
                          return [
                            _c(
                              "div",
                              { staticClass: "mb-4" },
                              [
                                _c("ValidationProvider", {
                                  attrs: { name: "Reason", rules: "required" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ errors }) {
                                          return [
                                            _c(
                                              "small",
                                              {
                                                staticClass:
                                                  "cancel-field-label",
                                              },
                                              [
                                                _vm._v(
                                                  " Why are you cancelling the patient? "
                                                ),
                                              ]
                                            ),
                                            _c("v-select", {
                                              staticClass: "cancel-selectbox",
                                              attrs: {
                                                placeholder: "Select one",
                                                options: _vm.cancelReasons,
                                              },
                                              model: {
                                                value: _vm.reason,
                                                callback: function ($$v) {
                                                  _vm.reason = $$v
                                                },
                                                expression: "reason",
                                              },
                                            }),
                                            _c(
                                              "small",
                                              { staticClass: "text-danger" },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(errors[0]) + " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "mb-4" },
                              [
                                _c(
                                  "small",
                                  { staticClass: "cancel-field-label" },
                                  [_vm._v(" Notes ")]
                                ),
                                _c("b-form-textarea", {
                                  staticClass: "px-0",
                                  attrs: {
                                    id: "textarea",
                                    rows: "3",
                                    "max-rows": "6",
                                  },
                                  model: {
                                    value: _vm.notes,
                                    callback: function ($$v) {
                                      _vm.notes = $$v
                                    },
                                    expression: "notes",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-row",
                              { staticClass: "mb-3" },
                              [
                                _c(
                                  "b-col",
                                  { attrs: { cols: "12", sm: "6" } },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        attrs: {
                                          pill: "",
                                          block: "",
                                          variant: "primary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.$bvModal.hide(
                                              "cancelPatient"
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(" Nevermind ")]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { cols: "12", sm: "6" } },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        attrs: {
                                          pill: "",
                                          block: "",
                                          disabled: _vm.isCancellingPatient,
                                          variant: "outline-danger",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return handleSubmit(
                                              _vm.onCancelPatient
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm.isCancellingPatient
                                          ? _c("b-spinner", {
                                              attrs: { small: "" },
                                            })
                                          : _c("span", [_vm._v("Confirm")]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("modal-set-reminder", { attrs: { patient: _vm.patientInfo } }),
      _c(
        "portal",
        { attrs: { to: "notes" } },
        [
          _c("ModalBodyNotes", {
            attrs: { "show-notes-modal": _vm.showNotesModal },
            on: {
              "update:showNotesModal": function ($event) {
                _vm.showNotesModal = $event
              },
              "update:show-notes-modal": function ($event) {
                _vm.showNotesModal = $event
              },
            },
          }),
        ],
        1
      ),
      _c(
        "portal",
        { attrs: { to: "pricing" } },
        [
          _c("ModalPricingList", {
            attrs: {
              "show-pricing-modal": _vm.showPricingList,
              patientId: _vm.patientInfo.id,
              pricingversion: _vm.patientInfo.pricingversion || "v1",
            },
            on: {
              "update:showPricingModal": function ($event) {
                _vm.showPricingList = $event
              },
              "update:show-pricing-modal": function ($event) {
                _vm.showPricingList = $event
              },
            },
          }),
        ],
        1
      ),
      _c("IntakeHistory", {
        attrs: {
          "show-modal": _vm.showIntakeHistory,
          "patient-info": _vm.patientInfo,
        },
        on: {
          "update:showModal": function ($event) {
            _vm.showIntakeHistory = $event
          },
          "update:show-modal": function ($event) {
            _vm.showIntakeHistory = $event
          },
        },
      }),
      _c("ModalDiscussTreatment", {
        on: {
          discuss: function ($event) {
            return _vm.$router.push(`/patients/${_vm.patientInfo.id}/message`)
          },
        },
      }),
      _c("ModalPrescribeEstrogenAlert", {
        attrs: { processing: _vm.isProcessing },
        on: { prescribe: (payload) => _vm.onRestartTreatment(true) },
      }),
      _c("modal-prescribe-estrogen-restart", {
        attrs: { processing: _vm.isProcessing },
        on: { prescribe: () => _vm.onRestartTreatment(true) },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }