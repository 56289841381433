<template>
  <b-row>
    <b-col cols="12" class="pl-0 tw-hidden xl:tw-block">
      <h2 class="mt-5 mb-5 bold primary-color" style="letter-spacing: 1px">
        Patient Medical Profile
      </h2>
    </b-col>
    <b-col cols="12" class="tw-mt-0 md:tw-mt-3 xl:tw-mt-0">
      <b-row class="content">
        <b-col cols="12" md="12" lg="4" xl="3">
          <b-card
            class="patient-info mb-0"
            style="background-color: transparent"
          >
            <template v-slot:header>
              <div class="tw-flex tw-justify-between tw-items-center">
                <h3 class="mb-0 bold">Patient Information</h3>
                <!-- <span v-if="patientInfo.id">
                  <span v-if="isEditing" class="text-secondary">
                    <b-spinner
                      v-if="isProcessing"
                      small
                      variant="secondary"
                    ></b-spinner>
                    <span v-else>
                      <a
                        class="
                          tw-uppercase
                          hover:tw-underline
                          tw-text-xs tw-tracking-wider
                        "
                        href=""
                        @click.prevent="isEditing = false"
                      >
                        Cancel
                      </a>
                      |
                      <a
                        class="
                          tw-uppercase
                          hover:tw-underline
                          tw-text-xs tw-tracking-wider
                        "
                        href=""
                        @click.prevent="onPatientProfileUpdate"
                      >
                        Save
                      </a>
                    </span>
                  </span>
                  <a
                    class="
                      tw-uppercase
                      hover:tw-underline
                      tw-text-xs tw-tracking-wider
                    "
                    href=""
                    @click.prevent="isEditing = true"
                    v-else
                  >
                    Edit
                  </a>
                </span> -->
              </div>
            </template>
            <b-row v-if="!patientInfo.id">
              <b-col cols="12" class="mb-5">
                <b-row align-v="center">
                  <b-col cols="auto">
                    <b-skeleton
                      animation="fade"
                      type="avatar"
                      size="85px"
                    ></b-skeleton>
                  </b-col>
                  <b-col>
                    <b-skeleton animation="fade"></b-skeleton>
                    <b-skeleton
                      animation="fade"
                      type="button"
                      width="100%"
                    ></b-skeleton>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="12" class="mb-4">
                <b-row align-v="start">
                  <b-col cols="12" md="6" v-for="i in 2" :key="i">
                    <b-skeleton
                      animation="fade"
                      height="10px"
                      width="50%"
                    ></b-skeleton>
                    <b-skeleton animation="fade"></b-skeleton>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="12" class="mb-4" v-for="i in 3" :key="i">
                <b-row align-v="start">
                  <b-col cols="12">
                    <b-skeleton
                      animation="fade"
                      height="10px"
                      width="50%"
                    ></b-skeleton>
                    <b-skeleton animation="fade"></b-skeleton>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <patient-information
              :key="patientInformationKey"
              :patient-info="patientInfo"
              :show-notes.sync="showNotesModal"
              :show-pricing.sync="showPricingList"
              :is-editing.sync="isEditing"
              :values.sync="editablePatientInformation"
              v-else
            />

            <template v-slot:footer>
              <b-row v-if="!patientInfo.id">
                <b-col cols="12" class="mb-3">
                  <b-skeleton
                    animation="fade"
                    type="button"
                    width="100%"
                  ></b-skeleton>
                </b-col>
                <b-col cols="12">
                  <b-skeleton
                    animation="fade"
                    type="button"
                    width="100%"
                  ></b-skeleton>
                </b-col>
              </b-row>
              <b-row v-else>
                <b-col cols="12" class="mb-3 text-center text-uppercase">
                  <a
                    class="d-block mb-3"
                    href="#"
                    @click="currentView = 'prescription-history'"
                  >
                    View Prescription
                  </a>
                  <a
                    class="d-block mb-3"
                    href="#"
                    @click="currentView = 'reminders'"
                  >
                    View Reminders
                  </a>
                  <a class="d-block" href="#" @click="currentView = 'followup'">
                    View Follow-up History
                  </a>
                </b-col>
                <b-col cols="12" class="mb-3 text-center text-uppercase">
                  <a href="#" @click.prevent="showIntakeHistory = true">
                    View Intake History
                  </a>
                </b-col>
                <b-col cols="12" v-if="!currentDoctor.id">
                  <b-button
                    pill
                    block
                    @click="chatPatient(patientInfo.id)"
                    variant="secondary"
                  >
                    Chat with Patient
                  </b-button>
                </b-col>
              </b-row>
            </template>
          </b-card>
        </b-col>
        <b-col
          cols="12"
          md="12"
          lg="8"
          xl="9"
          v-if="currentView === 'reminders'"
        >
          <reminder page="patient-profile" />
        </b-col>
        <b-col
          cols="12"
          md="12"
          lg="8"
          xl="9"
          v-else-if="currentView === 'prescription-history'"
        >
          <b-row>
            <b-col cols="12" class="mb-4">
              <b-row align-v="center">
                <b-col>
                  <h3 class="mb-0 bold">Prescription History</h3>
                </b-col>
                <b-col cols="auto" v-if="!currentDoctor.id">
                  <b-row class="tw-hidden xl:tw-flex" align-h="end">
                    <b-col cols="auto">
                      <b-skeleton
                        v-if="!patientInfo.id"
                        animation="fade"
                        type="button"
                        width="150px"
                      ></b-skeleton>
                      <b-button
                        v-else
                        pill
                        size="sm"
                        variant="danger"
                        style="min-width: 150px"
                        v-b-modal.cancelPatient
                      >
                        Cancel Patient
                      </b-button>
                    </b-col>
                    <b-col cols="auto">
                      <b-skeleton
                        v-if="!patientInfo.id"
                        animation="fade"
                        type="button"
                        width="150px"
                      ></b-skeleton>
                      <b-button
                        v-else
                        pill
                        size="sm"
                        variant="outline-primary"
                        style="min-width: 150px"
                        v-b-modal.viewDosespot
                      >
                        View Dosespot
                      </b-button>
                    </b-col>
                    <b-col cols="auto">
                      <b-skeleton
                        v-if="!patientInfo.id"
                        animation="fade"
                        type="button"
                        width="150px"
                      ></b-skeleton>
                      <b-button
                        v-else-if="
                          patientInfo && patientInfo.id && !patientInfo.isbanned
                        "
                        pill
                        size="sm"
                        variant="outline-primary"
                        style="min-width: 150px"
                        @click="onPrescribe"
                      >
                        Prescribe
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-dropdown
                    class="tw-block xl:tw-hidden"
                    variant="link"
                    toggle-class="text-decoration-none"
                    menu-class="custom-dropdown-menu"
                    no-caret
                    right
                  >
                    <template #button-content>
                      <b-icon icon="three-dots-vertical"></b-icon>
                    </template>
                    <b-dropdown-item @click="onPrescribe">
                      Prescribe
                    </b-dropdown-item>
                    <b-dropdown-item v-b-modal.viewDosespot>
                      View Dosepot
                    </b-dropdown-item>
                    <b-dropdown-item
                      link-class="!tw-text-alert"
                      v-b-modal.cancelPatient
                    >
                      Cancel Patient
                    </b-dropdown-item>
                  </b-dropdown>
                </b-col>
              </b-row>
              <div
                v-if="patientInfo.isbanned"
                class="
                  tw-bg-[#EB5757] tw-text-white tw-p-1 tw-my-4 tw-text-center
                "
              >
                Patient Banned - {{ patientInfo.banned_reason }}
              </div>
              <div
                v-else-if="patientInfo.cancelledbyusername"
                class="
                  tw-bg-[#EB5757] tw-text-white tw-p-1 tw-my-4 tw-text-center
                "
              >
                Canceled on
                {{
                  parseDate(patientInfo.fullycancelleddatetime, "MM/dd/yyyy")
                }}
                by
                {{ patientInfo.cancelledbyusertype === "DOCTOR" ? "Dr. " : ""
                }}{{ patientInfo.cancelledbyusername }}
              </div>
            </b-col>
            <!-- <b-col cols="12" class="mb-3">
            <b-row align-v="center">
              <b-col cols="6"><h3 class="bold">Treatment Plan Progress</h3></b-col>
              <b-col cols="6" class="text-right"><a href="#" class="text-primary bold" v-b-modal.notfit style="text-decoration: underline">Not fit for treatment</a></b-col>
            </b-row>
            <b-card class="dark-bg">
              <b-row>
                <b-col cols="12">
                  <b-row>
                    <b-col>
                      <p>Hot Flushes</p>
                    </b-col>
                    <b-col cols="auto">
                      <p>July 29, 2020</p>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="12">
                  <b-row>
                    <b-col>
                      <h3>60 x Black Cohosh 50mg</h3>
                    </b-col>
                    <b-col cols="auto"> {{ value }}% </b-col>
                  </b-row>
                </b-col>
                <b-col cols="12">
                  <b-progress
                    height="7px"
                    :value="progress"
                    class="mb-3"
                  ></b-progress>
                </b-col>
              </b-row>
            </b-card>
          </b-col> -->

            <b-col
              cols="12"
              :class="{
                'tw-opacity-75':
                  patientInfo.cancelledbyusername || patientInfo.isbanned,
              }"
            >
              <section-card-prescription
                ref="sectionCardPrescription"
                :items="ongoingItems"
                :is-add-refill-modal-open.sync="isAddRefillModalOpen"
                :selected-restart-prescription.sync="
                  selectedRestartPrescription
                "
                @refetch-prescriptions="cardRefetchPrescriptions"
                @refetch-single-prescription="cardRefetchPrescriptions"
              ></section-card-prescription>
              <b-col cols="12" class="pr-0 pl-0">
                <!-- <table-prescription-history></table-prescription-history> -->
                <dashboard-table
                  :table-header="fields"
                  :table-item="listViewItems"
                  :scroll-height="
                    ongoingItems.length > 0 ? '300px' : 'calc(90vh - 250px)'
                  "
                  :show-all-prescriptions="showAllPrescriptions"
                  scrollable
                  has-search
                  @prescribed="refetchPrescriptions"
                  @searchPrescription="searchPrescription"
                  @poll-prescriptions="cardRefetchPrescriptions"
                  @toggle-show-all="
                    showAllPrescriptions = !showAllPrescriptions
                  "
                  v-if="!isBusy"
                  class="patient-profile-table"
                  @open-restart-treatment="onOpenRestartTreatmentModal"
                  @open-add-refill="onOpenAddRefillModal"
                />
                <b-skeleton-table
                  animation="fade"
                  v-else
                  :rows="5"
                  :columns="5"
                ></b-skeleton-table>
              </b-col>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          cols="12"
          md="12"
          lg="8"
          xl="9"
          v-else-if="currentView === 'followup'"
        >
          <div class="tw-flex tw-justify-between tw-items-center mt-5 mb-4">
            <div class="text-primary">
              <h2 class="mb-0">Follow-up History</h2>
            </div>
            <b-button
              pill
              size="sm"
              variant="outline-primary"
              class="px-3"
              style="min-width: 150px"
              @click="isScheduleFollowupOpen = true"
            >
              Schedule a follow-up
            </b-button>
          </div>
          <table-followup
            :schedule-followup.sync="isScheduleFollowupOpen"
            :fields="followupFields"
            :items="followupItems"
            scroll-height="calc(90vh - 250px)"
            :no-header="true"
            scrollable
            @refetch="onFollowupRefetch"
            :bvToast="this.$bvToast"
          />
        </b-col>
      </b-row>
    </b-col>

    <modal-restart-prescription
      @restart="onRestartTreatment"
      :processing="isProcessing"
    />

    <b-modal id="viewDosespot" centered hide-footer size="xl">
      <template v-slot:modal-header="{ close }">
        <b-container fluid></b-container>
        <!-- Emulate built in modal header close button action -->
        <b-button size="sm" variant="link" @click="close">
          <b-icon
            icon="x-circle-fill"
            variant="secondary"
            style="width: 30px; height: 30px"
          ></b-icon>
        </b-button>
      </template>
      <b-row no-gutters>
        <b-col cols="12">
          <template v-if="isLoading && !this.dosespotURL">
            <b-row align-v="center" style="height: 350px">
              <b-col cols="12" class="text-center">
                <h1 class="text-primary">Loading Dosespot...</h1>
              </b-col>
            </b-row>
          </template>
          <iframe
            v-else
            :src="this.dosespotURL"
            width="100%"
            height="100%"
            frameborder="0"
            scrolling="yes"
            allowtransparency="true"
            :style="{ minHeight: iframeHeight + 'px' }"
          ></iframe>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal id="cancelPatient" hide-header centered hide-footer size="lg">
      <b-row style="position: relative">
        <b-col cols="12" sm="5" style="position: absolute; top: 0; left: 0">
          <svg
            width="30"
            height="27"
            viewBox="0 0 30 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M26.4395 18.5298L15.6275 6.36712H14.3725L3.5585 18.532L3.5365 0H0V27H1.3615L15.0005 10.8077L28.5975 26.8985L28.683 27H30V0H26.4175L26.4395 18.5298Z"
              fill="#1A6A72"
            />
          </svg>
        </b-col>
        <b-col cols="12" sm="8" offset-sm="2" class="py-6">
          <h1 class="heading mb-4" style="line-height: 30px">Cancel Patient</h1>
          <p class="mb-4">
            Are you sure you want to cancel this patient? This will cancel any
            outstanding refills, removes them from the three month follow up
            emails, and makes them non-active patients.
          </p>
          <ValidationObserver ref="notesForm" v-slot="{ handleSubmit }">
            <div class="mb-4">
              <ValidationProvider
                name="Reason"
                rules="required"
                v-slot="{ errors }"
              >
                <small class="cancel-field-label">
                  Why are you cancelling the patient?
                </small>
                <v-select
                  class="cancel-selectbox"
                  placeholder="Select one"
                  :options="cancelReasons"
                  v-model="reason"
                ></v-select>
                <small class="text-danger">
                  {{ errors[0] }}
                </small>
              </ValidationProvider>
            </div>
            <div class="mb-4">
              <small class="cancel-field-label"> Notes </small>
              <b-form-textarea
                id="textarea"
                class="px-0"
                v-model="notes"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </div>
            <b-row class="mb-3">
              <b-col cols="12" sm="6">
                <b-button
                  pill
                  block
                  @click="$bvModal.hide('cancelPatient')"
                  variant="primary"
                >
                  Nevermind
                </b-button>
              </b-col>
              <b-col cols="12" sm="6">
                <b-button
                  pill
                  block
                  :disabled="isCancellingPatient"
                  @click="handleSubmit(onCancelPatient)"
                  variant="outline-danger"
                >
                  <b-spinner v-if="isCancellingPatient" small></b-spinner>
                  <span v-else>Confirm</span>
                </b-button>
              </b-col>
            </b-row>
          </ValidationObserver>
        </b-col>
      </b-row>
    </b-modal>

    <modal-set-reminder :patient="patientInfo"></modal-set-reminder>

    <portal to="notes">
      <ModalBodyNotes :show-notes-modal.sync="showNotesModal" />
    </portal>

    <portal to="pricing">
      <ModalPricingList
        :show-pricing-modal.sync="showPricingList"
        :patientId="patientInfo.id"
        :pricingversion="patientInfo.pricingversion || 'v1'"
      />
    </portal>

    <IntakeHistory
      :show-modal.sync="showIntakeHistory"
      :patient-info="patientInfo"
    />

    <ModalDiscussTreatment
      @discuss="$router.push(`/patients/${patientInfo.id}/message`)"
    />

    <ModalPrescribeEstrogenAlert
      :processing="isProcessing"
      @prescribe="(payload) => onRestartTreatment(true)"
    />

    <modal-prescribe-estrogen-restart
      :processing="isProcessing"
      @prescribe="() => onRestartTreatment(true)"
    />
  </b-row>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { format, fromUnixTime, getUnixTime } from "date-fns";
import moment from "moment";

import { DashboardService } from "@/services/dashboard.service.js";
import { PatientService } from "@/services/patient.service.js";

import dashboardTable from "@/components/partials/DashboardTable";
import ModalSetReminder from "@/components/reminder/ModalSetReminder.vue";
import Reminder from "@/components/dashboard/Reminder.vue";
import SectionCardPrescription from "@/components/patients/SectionCardPrescription.vue";
import ModalBodyNotes from "@/components/patients/ModalBodyNotes.vue";
import ModalPricingList from "@/components/patients/ModalPricingList.vue";
import TableFollowup from "@/components/patients/TableFollowup.vue";
import PatientInformation from "@/components/patients/PatientInformation.vue";
import IntakeHistory from "@/components/patients/views/IntakeHistory.vue";

import { nanoid } from "nanoid";
import { ValidationObserver, ValidationProvider } from "vee-validate";

import ModalDiscussTreatment from "@/components/prescription/ModalDiscussTreatment.vue";
import ModalRestartPrescription from "@/components/patients/ModalRestartPrescription.vue";
import ModalPrescribeEstrogenRestart from "@/components/prescription/ModalPrescribeEstrogenRestart.vue";
import ModalPrescribeEstrogenAlert from "@/components/prescription/ModalPrescribeEstrogenAlert.vue";

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export default {
  name: "patient-profile",
  components: {
    dashboardTable,
    ModalSetReminder,
    Reminder,
    SectionCardPrescription,
    ModalBodyNotes,
    ModalPricingList,
    TableFollowup,
    PatientInformation,
    ValidationObserver,
    ValidationProvider,
    IntakeHistory,
    ModalDiscussTreatment,
    ModalRestartPrescription,
    ModalPrescribeEstrogenRestart,
    ModalPrescribeEstrogenAlert,
  },
  data() {
    return {
      isBusy: true,
      progress: 56,
      items: [],
      tempArrPrescriptions: [],
      max: 100,
      patientId: "",
      patientName: "",
      fields: [
        { key: "prescriptionstatus", label: "Status", sortable: true },
        { key: "datecreated", label: "Date", sortable: false },
        { key: "medicinename", label: "Drug Name", sortable: false },
        {
          key: "dosage",
          label: "Dosage",
          sortable: false,
        },
        { key: "unit", label: "Total Quantity", sortable: false },
        { key: "details", label: "Details", sortable: false },
        // { key: "actionOrder", label: "Action", sortable: false },
      ],
      dosespotURL: "",
      isLoading: false,
      iframeHeight: "",
      isCancellingPatient: false,

      currentView: "prescription-history",

      cancelReasons: [],
      notes: "",
      reason: "",

      showNotesModal: false,
      showPricingList: false,

      followupFields: [
        { key: "followupstatus", label: "Status", sortable: false },
        { key: "formattedfollowupdate", label: "Date", sortable: false },
        { key: "type", label: "Type", sortable: false },
      ],

      isScheduleFollowupOpen: false,
      isEditing: false,
      isProcessing: false,
      editablePatientInformation: {
        firstname: "",
        lastname: "",
        birthday: "",
        phoneno: "",
        shippingaddress: {
          address1: "",
          address2: "",
          zipcode: "",
          city: "",
        },
      },
      patientInformationKey: "",

      processingItemsLength: 0,
      showIntakeHistory: false,
      refreshPrescriptionCounter: 0,
      hasWaitingBeforeCleanup: false,
      hasWaitingOnCleanup: false,
      isPolling: false,
      recursiveTimoutFunction: null,
      showAllPrescriptions: true,

      showRestartTreatmentModal: false,
      selectedRestartPrescription: "",

      isAddRefillModalOpen: false,
    };
  },
  metaInfo() {
    return {
      title: this.websiteTitle,
    };
  },
  watch: {
    isEditing(val) {
      if (!val) {
        this.patientInformationKey = nanoid();
      }
    },
  },
  methods: {
    matchHeight() {
      let appHeight = window.innerHeight - 18;
      this.iframeHeight = appHeight;
    },
    chatPatient(patientId) {
      localStorage.setItem("patientId", patientId);
      this.$router.push(`/patients/${patientId}/message`);
    },
    async searchPrescription(filter) {
      this.items = [];
      if (filter != "") {
        let tempArray = [];
        let prescriptions = await this.$store.dispatch(
          "prescription/searchPrescriptionOrderPerPatient",
          {
            search: filter,
            patientid: this.patientId,
          }
        );
        for (let i = 0; i < prescriptions.length; i++) {
          let prescription = prescriptions[i];
          prescription.patientid = this.patientInfo.id;
          (prescription.prescriptionid = prescription.id),
            (prescription.name = `${this.patientInfo.firstname} ${this.patientInfo.lastname}`);
          if (prescription.prescriptionstatus == "DRAFTED") {
            prescription.actionOrder = ["Update", "Prescribe", "Delete"];
          } else if (prescription.prescriptionstatus == "FOR CONFIRMATION") {
            prescription.actionOrder = [
              "Update",
              "Send Confirmation",
              "Delete",
            ];
          } else if (
            prescription.prescriptionstatus == "PENDING CONFIRMATION"
          ) {
            prescription.actionOrder = ["Delete"];
          } else if (prescription.prescriptionstatus == "ACCEPTED") {
            prescription.actionOrder = ["Prescribe"];
          } else if (prescription.prescriptionstatus == "PRESCRIBED") {
            prescription.actionOrder = ["Cancel"];
          } else if (prescription.prescriptionstatus == "REFILL") {
            prescription.actionOrder = ["Refill", "Cancel"];
          } else if (prescription.prescriptionstatus == "COMPLETED") {
            prescription.actionOrder = ["Refill"];
          } else if (prescription.prescriptionstatus == "FOLLOW-UP") {
            prescription.actionOrder = ["Continue", "Stop"];
          } else if (prescription.prescriptionstatus == "DECLINED") {
            prescription.actionOrder = ["Delete"];
          } else if (prescription.prescriptionstatus == "FAILED") {
            prescription.actionOrder = ["Retry", "Cancel"];
          } else {
            prescription.actionOrder = "";
          }
          // prescription.actionOrder =
          //   prescription.prescriptionstatus == "DRAFTED"
          //     ? ["Update", "Prescribe", "Delete"]
          //     : "";
          tempArray.push(prescription);
        }
        Promise.all(tempArray).then((res) => {
          this.items = res;
        });
      } else {
        return await this.getPrescriptions("all");
      }
    },
    async refetchPrescriptions() {
      this.tempArrPrescriptions = [];
      await this.getPrescriptions("all");
    },
    async getPrescriptions(nextpage) {
      const res = await this.$store.dispatch(
        "prescription/getPrescriptionOrderPerPatient",
        {
          patientid: this.patientId,
          nextpage,
        }
      );
      this.tempArrPrescriptions = [];
      for (let i = 0; i < this.prescriptions.length; i++) {
        let prescription = this.prescriptions[i];
        prescription.patientid = this.patientInfo.id;
        (prescription.prescriptionid = prescription.id),
          (prescription.name = `${this.patientInfo.firstname} ${this.patientInfo.lastname}`);
        if (prescription.prescriptionstatus == "DRAFTED") {
          prescription.actionOrder = ["Update", "Prescribe", "Delete"];
        } else if (prescription.prescriptionstatus == "FOR CONFIRMATION") {
          prescription.actionOrder = ["Update", "Send Confirmation", "Delete"];
        } else if (prescription.prescriptionstatus == "PENDING CONFIRMATION") {
          prescription.actionOrder = ["Delete"];
        } else if (prescription.prescriptionstatus == "ACCEPTED") {
          prescription.actionOrder = ["Prescribe"];
        } else if (prescription.prescriptionstatus == "PRESCRIBED") {
          prescription.actionOrder = ["Cancel"];
        } else if (prescription.prescriptionstatus == "REFILL") {
          prescription.actionOrder = ["Refill", "Cancel"];
        } else if (prescription.prescriptionstatus == "COMPLETED") {
          prescription.actionOrder = ["Refill"];
        } else if (prescription.prescriptionstatus == "FOLLOW-UP") {
          prescription.actionOrder = ["Continue", "Stop"];
        } else if (prescription.prescriptionstatus == "DECLINED") {
          prescription.actionOrder = ["Delete"];
        } else if (prescription.prescriptionstatus == "FAILED") {
          prescription.actionOrder = ["Retry", "Cancel"];
        } else {
          prescription.actionOrder = "";
        }
        // prescription.actionOrder =
        //   prescription.prescriptionstatus == "DRAFTED"
        //     ? ["Update", "Prescribe", "Delete"]
        //     : "";
        this.tempArrPrescriptions.push(prescription);
      }
      this.nextPage = res.nextpage;
      this.items = this.tempArrPrescriptions;
      this.isBusy = false;
      return this.items;
    },
    async getSinglePrescription({ patientId, prescriptionOrderId }) {
      const res = await this.$store.dispatch(
        "prescription/getPrescriptionOrder",
        {
          patientId,
          prescriptionOrderId,
        }
      );
      return res;
    },
    async fetchDosespotIframe() {
      this.isLoading = true;
      const dosespotURL = await this.$store.dispatch(
        "prescription/generateDospatURL",
        this.patientId
      );
      this.dosespotURL = dosespotURL;
      this.isLoading = false;
    },
    async pollProcessingStatus(isRefill) {
      const items = this.items;
      const hasDelayUntilItems = items.filter(
        (item) => item.delayuntil && item.delayuntil >= getUnixTime(new Date())
      );

      if (hasDelayUntilItems.length === 0) {
        let hasWaitingItems = items.filter(
          (item) => item.prescriptionstatus === "WAITING"
        );
        if (hasWaitingItems.length > 0) {
          this.hasWaitingBeforeCleanup = true;
          this.cleanupWaitingInterval();
        }
      }

      if (!this.hasWaitingBeforeCleanup && hasDelayUntilItems.length > 0) {
        this.recursiveTimoutFunction = setTimeout(
          this.pollProcessingStatus,
          3000
        );
        this.isPolling = true;
      }
    },
    async cleanupWaitingInterval() {
      const hasWaitingItems = this.items.filter((item) =>
        ["WAITING", "DRAFTED", "PROCESSING"].includes(item.prescriptionstatus)
      );

      if (this.refreshPrescriptionCounter > 8) {
        this.refreshPrescriptionCounter = 0;
        this.hasWaitingOnCleanup = false;
        this.hasWaitingBeforeCleanup = false;
        this.isPolling = false;
        await sleep(3000);
        await this.getPrescriptions("all");
        return;
      }

      if (hasWaitingItems.length > 0) {
        this.hasWaitingOnCleanup = true;
        await sleep(3000);
        await this.getPrescriptions("all");
      } else {
        this.refreshPrescriptionCounter = 0;
        this.hasWaitingOnCleanup = false;
        this.hasWaitingBeforeCleanup = false;
        this.isPolling = false;
        await sleep(3000);
        await this.getPrescriptions("all");
      }
      this.refreshPrescriptionCounter += 1;
      if (this.hasWaitingOnCleanup) {
        setTimeout(this.cleanupWaitingInterval, 3000);
      }
    },
    async cardRefetchSinglePrescription(prescriptionid) {
      const updatedPrescription = await this.getSinglePrescription({
        patientId: this.patientId,
        prescriptionOrderId: prescriptionid,
      });
      let foundIndex = this.items.findIndex(
        (el) => el.id === updatedPrescription.id
      );
      if (foundIndex > -1) {
        this.items.splice(foundIndex, 1, updatedPrescription);

        if (
          !["PRESCRIBED", "FAILED"].includes(
            updatedPrescription.prescriptionstatus
          )
        ) {
          setTimeout(
            () => this.cardRefetchSinglePrescription(prescriptionid),
            3000
          );
        }
      }
    },
    async onCancelPatient() {
      try {
        this.isCancellingPatient = true;
        await PatientService.cancelPatient(this.patientId, {
          reason: this.reason.label,
          notes: this.notes,
        });
        await this.refetchPrescriptions();
        this.$store.dispatch("patients/getPatientInfo", {
          patientId: this.patientId,
        });
        this.$bvModal.hide("cancelPatient");
      } catch (e) {
        console.error(e);
      } finally {
        this.isCancellingPatient = false;
      }
    },
    async cardRefetchPrescriptions(arg = {}) {
      const { type = "", done = null, refill = false } = arg;
      await this.getPrescriptions("all");
      // To prevent 2 or more recursive api calls
      if (!this.isPolling) {
        this.pollProcessingStatus(refill);
      }

      if (done) done();
    },
    parseDate(date, stringFormat) {
      return format(fromUnixTime(date), stringFormat);
    },
    onFollowupRefetch() {
      this.$store.dispatch("patients/getPatientInfo", {
        patientId: this.patientId,
        isRefetch: true,
      });
      this.getPrescriptions("all");
    },
    async onPatientProfileUpdate() {
      try {
        this.isProcessing = true;
        await this.$store.dispatch("patients/updatePatient", {
          patientId: this.patientId,
          data: this.editablePatientInformation,
        });
        this.isProcessing = false;
        this.isEditing = false;
        this.patientInformationKey = nanoid();
      } catch (e) {
        this.isProcessing = false;
        this.$bvToast.toast(e.message, {
          title: "Something went wrong",
          variant: "danger",
          solid: true,
          toaster: "b-toaster-bottom-center",
        });
      }
    },
    onPrescribe() {
      if (
        this.patientInfo.letdoctorchoose &&
        this.patientInfo.pendingdoctormessage
      ) {
        this.$bvModal.show("discussPrescription");
        return;
      }

      this.$router.push(`/prescriptions/order/${this.patientInfo.id}`);
    },
    onOpenRestartTreatmentModal(prescriptionid) {
      this.$bvModal.show("restartPrescription");
      this.selectedRestartPrescription = prescriptionid;
    },
    async onRestartTreatment(force) {
      try {
        this.isProcessing = true;
        await this.$store.dispatch("prescription/restartTreatment", {
          patientid: this.patientInfo.id,
          prescriptionid: this.selectedRestartPrescription,
          payload: force ? { force: true } : null,
        });
        this.$bvModal.hide("restartPrescription");
        this.$bvModal.hide("prescribe-estrogen-restart-alert");
        this.$bvModal.hide("prescribe-estrogen-alert");

        this.isProcessing = false;
        setTimeout(async () => {
          await this.getPrescriptions("all");
          this.pollProcessingStatus();
        }, 3000);
      } catch (e) {
        this.isProcessing = false;
        this.$bvModal.hide("restartPrescription");
        this.$bvModal.hide("prescribe-estrogen-restart-alert");
        this.$bvModal.hide("prescribe-estrogen-alert");

        if (
          e?.message?.includes(
            "Patient has a Progesterone prescription cancelled by Stripe within the last 30 days"
          )
        ) {
          this.$bvModal.show("prescribe-estrogen-restart-alert");
        } else if (
          e?.message?.includes(
            "You are prescribing Estrogen to a patient that has not been prescribed Progesterone"
          )
        ) {
          this.$bvModal.show("prescribe-estrogen-alert");
        }
      }
    },
    onOpenAddRefillModal(prescription) {
      this.isAddRefillModalOpen = true;
      this.$refs.sectionCardPrescription.selectedPrescription = prescription;
    },
  },
  computed: {
    value() {
      return ((this.progress / this.max) * this.max).toFixed(0);
    },
    ongoingItems() {
      return this.items.filter(
        (item) =>
          !["REFILLED", "CANCELLED", "COMPLETED"].includes(
            item.prescriptionstatus
          ) && !(item.refills === item.paidrefills && item.refilluponrequest)
      );
    },
    listViewItems() {
      let statuses = [
        "REFILLED",
        "PRESCRIBED",
        "PAUSED",
        "PROCESSING",
        "SCHEDULED",
        "CANCELLED",
        "COMPLETED",
      ];
      // if (this.showAllPrescriptions) {
      //   statuses.push("CANCELLED", "COMPLETED");
      // }
      return this.items.filter((item) => {
        if (statuses.includes(item.prescriptionstatus)) {
          if (!this.showAllPrescriptions) {
            if (item.prescriptionstatus == "CANCELLED") {
              let cancelleddate = new moment(item.timestampcancelled * 1000);
              let now = new moment();
              return !(now.diff(cancelleddate, "days") > 30);
            } else if (item.prescriptionstatus == "COMPLETED") {
              let completeddate = new moment(item.completeddatestamp * 1000);
              let now = new moment();
              return !(now.diff(completeddate, "days") > 30);
            } else {
              return true;
            }
          } else {
            return true;
          }
        }
        if (item.refills === item.paidrefills && item.refilluponrequest) {
          return true;
        }
      });
    },
    websiteTitle() {
      const fullName = `${this.patientInfo.firstname} ${this.patientInfo.lastname}`;
      if (
        fullName &&
        (this.patientInfo.firstname || this.patientInfo.lastname)
      ) {
        return `Winona | ${fullName}`;
      } else {
        return `Winona | Patient Profile`;
      }
    },
    followupItems() {
      return this.followupSchedule.map((item) => ({
        ...item,
        formattedfollowupdate: this.parseDate(
          item.followupdatestamp,
          "MMMM d, yyyy"
        ),
      }));
    },
    currentDoctor() {
      return this.$store.state.globals.currentDoctor;
    },
    ...mapGetters({
      patientInfo: "patients/getPatientInfo",
      prescriptions: "prescription/getPrescriptionOrderPerPatient",
    }),
    ...mapState("patients", ["followupSchedule"]),
  },
  async mounted() {
    window.analytics.page();
    this.patientId = this.$route.params.id;
    this.matchHeight();

    if (!this.currentDoctor.id) {
      this.followupFields.push({
        key: "action",
        label: "Action",
        sortable: false,
      });
    }

    this.patientInformationKey = nanoid();

    window.addEventListener("resize", () => {
      this.matchHeight();
    });

    await this.$store.dispatch("patients/getPatientInfo", {
      patientId: this.patientId,
    });

    if (!this.currentDoctor.id) {
      await this.fetchDosespotIframe();
    }

    await this.getPrescriptions("all");
    this.pollProcessingStatus();
    await this.$store.dispatch(
      "patients/getPatientFollowupSchedule",
      this.patientId
    );

    DashboardService.getLookup("cancelreason").then(({ data }) => {
      this.cancelReasons = data.map((reason) => ({
        label: reason.name,
        code: reason.id,
      }));
    });
  },
  destroyed() {
    clearTimeout(this.recursiveTimoutFunction);
  },
  beforeRouteLeave(to, from, next) {
    next();
  },
};
</script>

<style>
.darkmode .cancel-selectbox .vs__search {
  color: white;
}
</style>

<style scoped>
/deep/.table thead th,
/deep/.table.b-table > thead > tr > .table-b-table-default,
.table.b-table > tbody > tr > .table-b-table-default,
.table.b-table > tfoot > tr > .table-b-table-default {
  background-color: rgb(250 251 252);
}

/deep/#viewDosespot .modal-dialog {
  min-width: 100% !important;
  min-height: 100% !important;
  margin: 0 !important;
}

/deep/#viewDosespot .modal-body {
  padding: 0;
}

/deep/#viewDosespot .modal-header {
  border: none;
}
</style>

<style lang="scss" scoped>
.text-muted {
  color: #4f4f4f !important;
}

.content {
  > div {
    padding: 20px;
    background-color: rgba($primary, 0.05);

    &:first-child {
      background-color: $quaternary;
      padding: 0;
    }
  }
}

.patient-info {
  .card-header {
    border-bottom: 0;
  }

  .card-body {
    height: calc(100vh - 350px);
    overflow: auto;
  }
}

@media (max-width: 768px) {
  .patient-info {
    .card-body {
      height: auto;
    }
  }
}

.patient-info .card-body {
  @include media-breakpoint-up(md) {
    height: calc(100vh - 48vh);
  }
}

.cancel-field-label {
  color: #828282;
}
</style>
